import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ジョブメドレー開発チームにおける 2 年目の成長と活躍",
  "date": "2023-02-28T06:15:36.000Z",
  "slug": "entry/2023/02/28/151536",
  "tags": ["medley"],
  "hero": "./2023_02_28.png",
  "heroAlt": "Title"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの新居です。今回は医療介護求人サイトの `}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{` 開発チームに所属している堀内さんに、2 年目の成長と活躍というテーマで話を聞いていこうと思います。`}</p>
    <h1>{`インタビュイー紹介`}</h1>
    <h2>{`堀内さん`}</h2>
    <p>{`2021 年入社。人材プラットフォーム本部 プロダクト開発室 第一開発グループ所属。ジョブメドレーの開発を担当。現在は求職者側の UI/UX 改善などの開発に携わる。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/37edb2e445a15f7685da40b1ef419339/eea4a/note202302_001.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQb/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABZZi6PREEj//EABsQAAEEAwAAAAAAAAAAAAAAAAIBAwQyABET/9oACAEBAAEFAnnRAIxgre8l2JE4BT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EAB4QAAIBAwUAAAAAAAAAAAAAAAACEQEQcRIhIkFR/9oACAEBAAY/AtLTy8ITqy4KbC4P/8QAGhABAQEBAAMAAAAAAAAAAAAAAQARITFBof/aAAgBAQABPyH0wcm1uPJG+S+lAcA4dlT/2gAMAwEAAgADAAAAECPv/8QAFhEBAQEAAAAAAAAAAAAAAAAAERAh/9oACAEDAQE/EByP/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPxAZE//EAB0QAQADAQACAwAAAAAAAAAAAAEAESExQVGB8PH/2gAIAQEAAT8QQk1RxRxbgPSEOFu5fiF0XzNW/RnNpYU99xO7+mf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "堀内さん",
            "title": "堀内さん",
            "src": "/static/37edb2e445a15f7685da40b1ef419339/e5166/note202302_001.jpg",
            "srcSet": ["/static/37edb2e445a15f7685da40b1ef419339/f93b5/note202302_001.jpg 300w", "/static/37edb2e445a15f7685da40b1ef419339/b4294/note202302_001.jpg 600w", "/static/37edb2e445a15f7685da40b1ef419339/e5166/note202302_001.jpg 1200w", "/static/37edb2e445a15f7685da40b1ef419339/eea4a/note202302_001.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`堀内さん`}</em></p>
    <h1>{`メドレーに入社した理由`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: まずはじめに、堀内さんはどういう経緯でメドレーに入社したのでしたっけ？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 以前のインタビュー（`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2022/07/22/214813"
      }}>{`21 年新卒入社エンジニアと座談会で振り返る新卒研修`}</a>{`）の時にもお話したのですが、就活の軸として、自分が成長できそうか・風通しが良いか・合理的な社風かなどを軸として探していました。特に､`}<strong parentName="p">{`ビジネス側と開発側の距離が近い`}</strong>{`部分に惹かれ､総合的にメドレーが良さそうだと考えて入社しました。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 入社前の印象と実際働きだしてからで、ズレなどはありましたか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: そんなにギャップはなくて、社風とか人間関係みたいなところは入社前のイメージ通りでした。入社前に何回か会社見学をさせてもらったり、その時の社員と複数回面談をセッティングしてもらったりして、そこでイメージをすり合わせできたことが大きかったですね。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 何人くらいの社員と会ったのですか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 6 ~ 7 人です。色々なポジションや性格の人達と話ができたので、安心して入社することができました。`}</p>
    <h1>{`新卒研修の開発 OJT ではどんなことをやっていたか？`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: 堀内さんは新卒研修の開発 OJT のタイミングでジョブメドレー開発チームに仮配属されたのですよね。当時はどういうことをやってたのですか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: まずはジョブメドレーのドメイン知識をしっかり吸収していこうということで、業界や業務自体の理解を進めました。`}</p>
    <p>{`その後、求職者と事業者のサポート業務を行う社内オペレーターが利用する画面の拡張タスクに取り組みました。`}</p>
    <p>{`技術スタックは `}<a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`Next.js`}</a>{` / `}<a parentName="p" {...{
        "href": "https://ja.reactjs.org/"
      }}>{`React`}</a>{` / `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/"
      }}>{`TypeScript`}</a>{` / `}<a parentName="p" {...{
        "href": "https://graphql.org/"
      }}>{`GraphQL`}</a>{` といったシステムで、個人的にあまり触れてこなかった領域だったので、技術のキャッチアップもしつつ丁寧に対応していきました。`}</p>
    <p>{`また、開発 OJT よりも前の研修で輪読会も経験したのですが、その時に学んだ `}<strong parentName="p">{`Cookie や Session まわりの知識が役に立ったり、座学で学んだことがこうやって実践で活きてくるんだな、ということを実感`}</strong>{`できてとても良い経験になりました。`}</p>
    <p>{`今までとは比べ物にならないくらい大規模なシステムだったので、プレッシャーも大きかったのですが、チームの人達と相談したり、細かいレビュー・サポートがあったおかげで、しっかり成果を出すことができたと思います。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 良い OJT ですね！堀内さん自身の努力があったのは勿論ですが、チームに新しいメンバーを迎え入れて成長をサポートする環境が整っていたのも良かったんでしょうね。`}</p>
    <p>{`ちなみに開発 OJT の中で大変だったこととかはありますか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: そうですね、やはり大規模な既存コードの把握・理解が大変でした。社内オペレーターが使う画面とジョブメドレー本体はそれぞれ別システムとして連携しているので、ジョブメドレー本体のコードや仕組みの理解も必要です。`}</p>
    <p>{`大変だった分、`}<strong parentName="p">{`システムの全体像を理解するのに大いに役立った`}</strong>{`ので、今思い返すと良い経験だったなあと思います。`}</p>
    <h1>{`色々な経験を積めた求人カード改修プロジェクト`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: では、新卒研修を終えてから 1 年以上経っていて、入社 2 年目の終わりに差し掛かっている現在ですが、直近で堀内さんがリードを務めていた、ジョブメドレーの求人カード改修プロジェクトについて話を聞いていきたいと思います。まずは、このプロジェクトの概要について、教えてもらえますか？`}</p>
    <h2>{`求人カード改修プロジェクトとは？`}</h2>
    <p><strong parentName="p">{`堀内`}</strong>{`: それでは､プロジェクト説明に先立ちまして､まず、ジョブメドレーの「求人カード」について簡単に説明します。
ジョブメドレーで求人を検索すると､検索結果が並ぶ画面が出てきます。我々が「求人カード」と呼んでいるのは、この検索結果画面上に表示される、求人情報のことになります。`}</p>
    <p>{`検索結果画面の求人カードでは、給与や大まかな業務内容、応募要件、職場の住所などが分かる形になっていますが、求職者の方々がより便利に使っていただけるよう、情報の出し方を見直すことが今回のプロジェクト内容です。`}</p>
    <p>{`もう少し詳しくお話すると､採用決定率の改善を目的に､どのような改善を実施すべきか検討しています｡
求職者にとって欲しい情報が手に入りやすくなるということは、その分、応募に繋がりやすくなると言い換えることもできますので､改修による効果については、継続的に検証し､効果の有無を明らかにしたいと考えています｡`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: なるほど。端的に言うと、ジョブメドレーにおける求人情報検索の体験を更に良くする為のプロジェクトということですね。`}</p>
    <h2>{`プロジェクトのはじまり`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 今回のプロジェクトをどのようなメンバーと何をどのように行っていったのか、チーム構成から教えてください。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: チーム構成としては、自分を含めたエンジニアが 2 人いて、その他は、デザイナー、マーケター、プロダクトマネージャー（以下、PdM）がそれぞれ 1 人ずつプロジェクトにアサインされました。`}</p>
    <p>{`プロジェクトのきっかけとしては、求職者体験を更に良くする為の企画の一つとしてマーケターと PdM が企画したところから始まり、その後、エンジニアとマーケター間で､細かい具体的な要件まで落とし込んで、プロジェクト化に至りました。`}</p>
    <h2>{`仕様についてメンバー間で徹底議論`}</h2>
    <p><strong parentName="p">{`堀内`}</strong>{`: 要件まで落とし込んだ後は、具体的にそれらをどうやって実装するかを検討しました。特に `}<strong parentName="p">{`UI の仕様については、デザイナーを中心にかなり長い時間をかけて詰めていきました`}</strong>{`。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 確かに、情報の出し方によっては、かえって、求職者体験を悪化させてしまう可能性がありそうですね…。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: はい。使い勝手の良い UI にする為に、デザイナーがまず `}<a parentName="p" {...{
        "href": "https://www.figma.com/ja/"
      }}>{`Figma`}</a>{` でモックアップを作り、プロジェクトが始まった最初の週からデイリーの夕会などで、UI に関して徹底的にプロジェクトメンバー間で議論をしていました。`}</p>
    <p>{`その時、特にメンバー全員が気を付けていたのは、実際に求職者が新しい一覧画面を使う時に、価値を感じてもらえるかどうかです。メドレーの `}<a parentName="p" {...{
        "href": "https://www.medley.jp/team/culture.html"
      }}>{`Our Essentials`}</a>{` の一つとして、`}<strong parentName="p">{`長期のカスタマー価値を追求`}</strong>{` という項目があります｡「全ての利用者にとって価値のある施策であること」､「利用者の片方だけを見て、もう片方を無視することがあってはならない」ということで、事業者側の求人情報を多く載せるだけではなく、求職者にとっての使い勝手も向上しているか？などを徹底的にチェックしていました。`}</p>
    <p>{`使い勝手をチェックするにあたって、ジョブメドレーのメインユーザーの年齢層などが予め分かっていたので、その方達にとって使いやすいかどうかを判断軸として、考えていました。`}</p>
    <p>{`デザイナーが完成させた UI でも、でき上がったモックアップをメンバーそれぞれの視点で触ってみると､改善点が出る場面がありました。そういった場合には、こういう UI にしてみたらどうか？などとそれぞれが意見し、良さそうな案についてはデザイナーがそれらの提案を取り込んだり､改良したりして、UI の仕様を詰めていきました。`}</p>
    <h2>{`技術面について`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: UI を実装に落とし込む際、技術面で工夫した点や気を付けていたポイントなどはありますか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 今回のプロジェクトでは、求人カードのデザインを大幅に変更する為､事前の影響範囲の洗い出しを徹底するよう注意していました｡例えば、一覧画面における求人情報の出力量がかなり増えることで、1 ページあたりの読み込み処理に時間がかかってしまうことが予想された為､コンテンツの遅延読み込みやキャッシュの利用によりチューニングを行っています｡また､デザイン変更が多くのページにわたって発生する為､リリース後の不具合を起こさないような対策を複数行っています｡`}</p>
    <h2>{`担当したプロジェクトのリード`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 今回のプロジェクトでは、エンジニアとして開発をしつつ、堀内さんがプロジェクトのリードを任されていたと聞いています。リードとしては、どんな動きをプロジェクトの中でされていたのでしょうか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: まずは、最初にプロジェクト全体の大まかなスケジュールを引き、PdM と確認しました。次に、タスク分解をしてメンバーへのアサインを行ったり、プロジェクトのタスクのなかで不確実性を極力少なくする為に、自分達だけではコントロールできなさそうな部分を洗い出したりしました。洗い出したアンコントローラブルな部分は企画者と共に関係各所へ連絡・調整をしてもらうようお願いしていました。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: なるほど。開発スケジュールの出し方など、その辺りで工夫した点などはありますか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: スケジュールの共有方法については、視覚的に分かりやすいように、`}<a parentName="p" {...{
        "href": "https://www.figma.com/ja/figjam/"
      }}>{`FigJam`}</a>{` を使って、ガントチャートのような形式で、ボードを作成しました。`}</p>
    <p>{`メンバー毎にレーンを振り分けて、誰がいつまでに何をしなければいけないのかを夕会で確認していました。また、スケジュールのボードは常に、`}<strong parentName="p">{`第三者が見ても進捗状況が直ぐに分かるような状態にしておく`}</strong>{`よう努めました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/62fe621d3ce737ae68fe454551b35dc4/3a4f1/note202302_004.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVQY0y3GO2+bQAAAYP5l1SlxVKlS93bv0Nmq1CFDFxelD5vGSnDhAjlMMM/jwDUkxoID8zAP15dXq0jNFqnqN31MVBiwAeh66lBo7eSLTkR0alNoUOBcKxaVTQrcG4ioYtAzY3cWOOkGPVTeQxv8YcJMU0NORtzp7OuJdgTMT+cup2BOsMfA5oDFAXsYJl/Kmt3UbFmza2NGJG+t2LkaMTleLTUNQwNDf6HOmzlJdJLZWYtXlVdWP7vCb5qw3S7ru6j6vcxTdXcl30bKTTL7y3yPk2GAhpY+NnU+DjjTGfuIJ0tIDKHKxW4ntZ1UN2DdjHPKJveTdnvcbSddq99R5nYhNqG0ccWFzrv4R+GB0BVtZ+Ig4Fh86guxK54o06HufrhIPqurX5fapa/fB9JjajKsaI1kxCl4IKOPwP4G8aGA+6e4z3vveXwozPu8/w4kb6VqoEahdSwo+gjikYSgdcU8i4v9uNgj1T4pe6TcI1XvX3rp/xyQ8kVaHJDyFclek8XLOHueVW/sfOA0T4JERmaRHME/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "スケジュールボード",
            "title": "スケジュールボード",
            "src": "/static/62fe621d3ce737ae68fe454551b35dc4/c1b63/note202302_004.png",
            "srcSet": ["/static/62fe621d3ce737ae68fe454551b35dc4/5a46d/note202302_004.png 300w", "/static/62fe621d3ce737ae68fe454551b35dc4/0a47e/note202302_004.png 600w", "/static/62fe621d3ce737ae68fe454551b35dc4/c1b63/note202302_004.png 1200w", "/static/62fe621d3ce737ae68fe454551b35dc4/d61c2/note202302_004.png 1800w", "/static/62fe621d3ce737ae68fe454551b35dc4/97a96/note202302_004.png 2400w", "/static/62fe621d3ce737ae68fe454551b35dc4/3a4f1/note202302_004.png 2588w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`スケジュールボード`}</em></p>
    <p>{`スケジューリングで特に意識していたことは、不確実な部分を早めに洗い出すことでした。タスク分解をしていく中で、どこが自分達だけではコントロールできなさそうな部分なのかを把握することから始めていました。`}</p>
    <p>{`現状の仕様や実装がどうなっているのかなどは直ぐに確認できるので、そういったところから、なるべく不確実な部分を減らしていき、最終的に各タスクの工数を算出するにあたって、不確実性の高さに応じて、バッファを設定しました。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: 少し手を動かせば確認できるようなところは直ぐに確認して、なるべく解像度が高い状態にもっていく進め方は確かに良さそうですね！工数を見積もるにあたって、解像度が低い状態だと、見積もりの精度が低くなってしまいますからね。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: また、タスクは GitHub Issue を発行して管理していたのですが、切り出す粒度はなるべく細かくしていました。Issue の粒度が荒くなってしまうと、それに比例して手戻りリスクが高くなってしまうので、目安としては、長くても 2 日以内には完了できる粒度で切り出していました。`}</p>
    <p>{`結果、今回のプロジェクトにおいては、ほとんど手戻りすることなく、ほぼ全ての期間において、オンスケジュールで進めることができました。`}</p>
    <h2>{`A/B テストの導入（効果検証の仕組み化）`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: オンスケで進められていたのは素晴らしいですね！開発者として実装することと、リードとしてプロジェクトを管理することの他、堀内さんが今回のプロジェクトで担当していた役割などはありますか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 今回のプロジェクトでは、効果検証を行う為の仕組みとして、A/B テストを本格的に導入する目標がありました。なぜ A/B テストを導入するのか？ A/B テストで何をしたいのか？を明確にして、A/B テストの設計から導入､分析までを行いました。`}</p>
    <p>{`統計的な分析手法の選択から実際のテスト環境の開発みたいなところまで一気通貫で、PdM、マーケター、エンジニアを巻き込んで行いました。`}</p>
    <p>{`A/B テストで大事なのは、論理的な仮説を立ててそれを検証し、その結果からより効果があると判断できた施策を導入していく、というサイクルだと考えています｡とはいえ、有意差が出たからといって必ずしも導入したほうが良いというわけではありません｡その時の経営面のメリット・デメリットを考慮し､これまでの経験にもとづいた判断も加えて、より効果の高い施策を継続的に導入できるようにする、というのが今回の目標です｡`}</p>
    <p>{`今回のプロジェクトを皮切りに、他のプロジェクトでも同じように A/B テストの仕組みを使える状態にしたい。そういった横展開や汎用性みたいなところも、プロジェクト当初からセットで考えていました。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: とても興味深い取り組みですね！実際、どのように仕組み化を進めていくのでしょうか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: A/B テストに必要な知見をドキュメント化して、それを見るだけで、エンジニアのサポートを受けつつ誰でも A/B テストが実施できるようにしていく取り組みや､スプレッドシートにデータを入力すれば、簡単に結果が判るような仕組み化を進行中です｡
機能のリリース時は､その結果に応じて結局、どうすれば良いのか？といった指針も Issue やドキュメントに記載しております｡`}</p>
    <h2>{`実装や設計面における悩み`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 実装や設計面において、今回のプロジェクトで悩んだようなところはありましたか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: はい。自分自身の力量の問題で、ドメイン知識や技術力などが不足していたことから、ジョブメドレーのコードの中で今までの慣習を受け継ぎつつ、良い感じに使い回しができるコードをどう上手く書いていくか、という点について悩んでいました。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: なるほど…。ジョブメドレー自体が 10 年以上の歴史があるサービスなので、そこに手を入れていくのは確かに難しい部分もありそうですね。その悩みに対しては、どう対策をされていたのでしょう？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: そうですね。今の自分の知見だけで設計と実装を進めてしまうと手戻りが発生してしまう恐れがあったので、まずはドメイン知識が豊富な、同じチーム内のベテラン社員に設計レビューをお願いしました。そこで、ジョブメドレーのインフラ構成におけるキャッシュ戦略や､保守性を高める為の実装の切り出し方など、壁打ちで相談させてもらっていました。`}</p>
    <p>{`その先輩社員との設計レビュー以外にも、普段から行ってもらっている 1on1 ミーティングを通じて、自分のメンターと相談させてもらい、どういう設計だったら使いやすいのか、などを様々な視点から検討しました｡`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: なるべく手戻りが発生しないようにする為に、`}<strong parentName="p">{`設計段階で身近にいるエンジニアを巻き込んで進めていった`}</strong>{`のですね。直ぐに相談できる相手がいるのは心強いですね。`}</p>
    <h2>{`学びになった点`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 今回のプロジェクトを通じて、特に学びになった点を教えていただけますか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 技術的な面とプロジェクトの進め方の面でそれぞれ学びがありました。`}</p>
    <p>{`技術的な面で言うと、`}<strong parentName="p">{`保守性が高く、今後、運用しやすいコードとはどういうものなのかというのを、今回の実装を通じて体得できた`}</strong>{`ところだと思っています。`}</p>
    <p>{`プロジェクトの進め方の面では、過去のプロジェクトのドキュメントを参考に、そこに書かれていた知見を活かしながら、プロジェクトを進めていったので、ノウハウのようなところが学べたかと思っています。`}</p>
    <h2>{`新卒研修・開発実践のリードとの違い`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 堀内さんは、新卒研修の一環で行われた開発実践の期間中、新卒同士のチーム開発でもリードを務めていたと思います。今回のプロジェクトを通じて経験されたリードとの違いについて教えてください。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: プロジェクトの規模や影響範囲の違いなどから、研修時よりも多くの関係者との調整が必要でした｡全員と情報を共有・連携しつつ､プロジェクトを進める必要があった点が一番大きな違いでした。`}</p>
    <p>{`また、開発実践のプロジェクトはあくまで社内向けシステムを構築するものだったので、会社の売上について意識することはありませんでしたが、今回のプロジェクトでは、施策によって応募率が下がった場合に売上に悪影響を及ぼす可能性がありましたので、開発部署以外への影響を意識して管理する必要があったことも大きいポイントだったと思っています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b57c4e46c40475dc7d8f36346af76d9a/eea4a/note202302_002.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABdSpcBpmDP//EABkQAAIDAQAAAAAAAAAAAAAAAAEDAAIRBP/aAAgBAQABBQJWUXXp18wFfIBs/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BZ//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ASf/xAAdEAAABQUAAAAAAAAAAAAAAAAAARARIQIiMVFh/9oACAEBAAY/Ai0CsLLPxJFUJ//EABsQAAICAwEAAAAAAAAAAAAAAAERADEQIUFx/9oACAEBAAE/IUKreRyDjsXDiEACH2MKMY//2gAMAwEAAgADAAAAEBj/AP/EABURAQEAAAAAAAAAAAAAAAAAABBh/9oACAEDAQE/EIP/xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAgEBPxCj/8QAGRABAQEBAQEAAAAAAAAAAAAAAREhABCR/9oACAEBAAE/EBSEI3A70YByDpn3mHiyIsFOcpEIpp5//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "堀内さん",
            "title": "堀内さん",
            "src": "/static/b57c4e46c40475dc7d8f36346af76d9a/e5166/note202302_002.jpg",
            "srcSet": ["/static/b57c4e46c40475dc7d8f36346af76d9a/f93b5/note202302_002.jpg 300w", "/static/b57c4e46c40475dc7d8f36346af76d9a/b4294/note202302_002.jpg 600w", "/static/b57c4e46c40475dc7d8f36346af76d9a/e5166/note202302_002.jpg 1200w", "/static/b57c4e46c40475dc7d8f36346af76d9a/eea4a/note202302_002.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`ジョブメドレーの開発におけるやりがい`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: ここまで直近で担当されていたプロジェクトについて聞いてきましたが、ジョブメドレーの開発をしていて、特にやりがいを感じる場面について、教えてもらえますか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: やはり､利用してくださっている求職者や事業者の方々に向けて､新規機能開発や機能改修を行う工程です｡目的と照らし合わせて仕様面から妥協せずに考え抜き、リリースするまでの過程にやりがいを感じています｡ユーザー数がとても多いだけに､良い機能をリリースできたときの反響も大きく､非常に楽しいです｡`}</p>
    <p>{`勿論、エンジニアとして技術力を上げていくことも貪欲に今後もやっていかなければならない部分だと思っていますが、企画として案件があがってきた段階で、それをなぜ作るのか？なぜそれで効果が出ると思っているのか？などと考えることが、企画職だけでなく､エンジニアにとっても大事だと考えています｡`}</p>
    <p>{`リリースした後には、実際に効果が出たのか出なかったのか、なぜ効果が出たのか､出なかったのか？などの効果検証を行った上で、PdM を中心にエンジニア以外のメンバーも交えて振り返り、次の施策に活かしていくといった、`}<strong parentName="p">{`PDCA サイクルを回していっている開発スタイルに楽しさを感じています`}</strong>{`。`}</p>
    <p>{`これは、自分の入社時にやりたいこととしてあげていた、エンジニアリング面だけではなくビジネス面についても多くの経験を積みたい、というところにリンクしていて、今在籍しているジョブメドレーの開発チームでは、企画やマーケティングを行う人達とエンジニアがとても近い距離にいることで、様々な知見を得られつつアウトプットできる､とても学びになる環境だと感じています。`}</p>
    <h1>{`ジョブメドレー開発チームにおける 1 日の流れ`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: 現在のジョブメドレー開発チームは、Growth Unit（求職者の利便性を高める施策を行うチーム）と Customer Unit（求人を掲載する事業者が使う採用管理システムの改善施策を行うチーム）の 2 つに分かれているんですよね。堀内さんが所属しているのは、Growth Unit の方ですが、参加されているミーティングなど、1 日の流れについて教えてください。`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: まず、勤務開始の 10:00 頃には Slack に投稿する形で、その日のタスクや参加予定のミーティングなどについて、開発用 channel で共有します。`}</p>
    <p>{`現在の自分のスケジュールでは、午前中は特に定常的なミーティングはありません。但し、アサインされるプロジェクトによっては、午前中に「朝会」としてプロジェクトメンバー同士が集まって、進捗共有などを行っています。`}</p>
    <p>{`お昼過ぎの 14:30 からは､ 30 分程度､Growth Unit のエンジニアだけで集まるミーティングがあります。この場では、各自の進捗状況や抱えている不安・不明点などを共有します｡今何に困っているのかを共有するだけでなく､`}<strong parentName="p">{`困りごとに対し､各々が知恵を出し合って、問題解決までのリードタイムを短くする`}</strong>{`ことができる貴重な場となっています｡`}</p>
    <p>{`18:00 からは「夕会」として、PdM とデザイナーも含めた Growth Unit 全体のミーティングがあります。そこで各自、「今日やったこと」や担当 Issue のリリース目処などを共有しています。この場では、エンジニア以外のメンバーも参加しているので、そこまで技術的に突っ込んだ話まではしません。`}</p>
    <p>{`さらに、終業前にはまたエンジニアだけで集まり、FigJam を使って付箋を貼っていく形で、`}<strong parentName="p">{`各自が技術的トピックや、特定のドメインについて聞きたいことなどを持ち寄り、ざっくばらんに雑談形式で話す`}</strong>{`「技術共有会」を行っています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/11f7d31c27c7b790b120e0ec8683b554/df5d6/note202302_005.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACK0lEQVQozwXBy0/TcAAA4P45xKAZYRAx0Xg0xgscMMbExMjBB3qQyzyQRWMCCyYqykMTYYFBRBJlbGvXtb9fR9uta7t2a7uu2+hjbOsejKGAId7w+5CbeGSUY4b2gIeMeSDRD4grEPeAxBANrqaBF8YGAeohsX7IDEBiAGCXSXwYxkcofITCkYcM3MhFwgL2TXcCUihibEVLyRlt+/Hq59E3C2/Vhk/JTOfjmDTtFzZ8Mtyx6LsZxguwaxSOfEwVLyT2pPJFb+bfy9TXAjErAvfMDs4t3B97mj29WGPp+YVAV/NpLnROe+fn5vN8ZhBg15MJJMAqR3q24aKKtTa8B/qoeB+JPlPkB7uxW6tBv62MwdhgKHiblccF9p6wN86nbyQTHhIbgnHkEUutCxKZN7ad5kse+CC9qBuTMjqjE0sHpQl8N6Bm5h1lKrv1moNzOWauQr+Q8CVTnlJ45JWoqYWOKral1iFT1PN8RzA6lKa4h9XKaY1IN+xetfjbKh7resfQCkzRLkddvlLnFtUUMskk1wk5bhVCBvdTy4ldlz2uLnPMjqSyzTrXbWw6wo+qmOrVN8v8Mo3i5TrR3n9XEu+kSGQ2mysJR3nzIIyrLe2kUm/b5y3AFR2pZ9fb1lmT79rm37b77xBna0mhVP1TcNuS39AuJaLIEym5aLIrloy6tU9pGDezaMtcLgsrUnrHylIdO+zur5XoXw3lA0dvStp3h00YmQkx5YXYf1PLg8VXrCjWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "技術共有会の雰囲気",
            "title": "技術共有会の雰囲気",
            "src": "/static/11f7d31c27c7b790b120e0ec8683b554/c1b63/note202302_005.png",
            "srcSet": ["/static/11f7d31c27c7b790b120e0ec8683b554/5a46d/note202302_005.png 300w", "/static/11f7d31c27c7b790b120e0ec8683b554/0a47e/note202302_005.png 600w", "/static/11f7d31c27c7b790b120e0ec8683b554/c1b63/note202302_005.png 1200w", "/static/11f7d31c27c7b790b120e0ec8683b554/d61c2/note202302_005.png 1800w", "/static/11f7d31c27c7b790b120e0ec8683b554/97a96/note202302_005.png 2400w", "/static/11f7d31c27c7b790b120e0ec8683b554/df5d6/note202302_005.png 2514w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`技術共有会の雰囲気`}</em></p>
    <p>{`ミーティングの場以外は黙々と開発を進めていきますが、聞きたいことや相談したいことが出てきたら都度、開発用のスレッドに投稿しています。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: なるほど。質問や相談はしやすい感じですか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: はい。勿論、自分で調べられることはなるべく調べて、自己解決するのが基本ではありますが、あまり調査に時間をかけ過ぎて全然進まないような事態に陥っても良くないので、それらを皆、前提として意識した上で、聞くべき時は躊躇なく聞ける雰囲気があります。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: ミーティングの種類としては、先ほどあげてもらったもの以外にありますか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: あとは、週一で行われている「プロダクト定例」と隔週で行ってもらっている「1on1 ミーティング」があります。`}</p>
    <p>{`プロダクト定例は、比較的規模の大きいミーティングで、我々、プロダクト開発室以外のメンバーの他、マーケティング室や事業企画室のメンバーも含めて、大体いつも 40 名前後の人数で参加しています。`}</p>
    <p>{`この場では、現在追っている KPI だったり、ジョブメドレーで動いている全てのプロジェクト状況が共有されます。そこで共有される KPI の数字によって、次に着手すべき Issue にも影響してくるので、この場で共有される情報をしっかりとインプットして、今後の心構えとすることにしています。`}</p>
    <p>{`直近で担当したプロジェクトの話でもちらっと出てきましたが、1on1 ミーティングは担当メンターと行なっています。普段の仕事の相談から、自分のキャリアに関するような相談をしており、メンターさんの経験なども教えていただきながら、勉強させていただいています。`}</p>
    <p>{`1on1 ミーティングの意義としては、そういった仕事の他、`}<strong parentName="p">{`プライベートのことも含めて、何でも相談ができる場`}</strong>{`としてあるのですが、1on1 を通じて、メンターさんとの信頼関係も築けるので、チームビルディングの一つとしても機能しています。`}</p>
    <h1>{`ジョブメドレー開発チームで一緒に働きたいと思う人はどんな人？`}</h1>
    <p><strong parentName="p">{`新居`}</strong>{`: 堀内さんがジョブメドレー開発チームで一緒に働きたいと思う人はどんな人でしょうか？`}</p>
    <p><strong parentName="p">{`堀内`}</strong>{`: 今の環境で働いてて良かったなあと思うことは、`}<strong parentName="p">{`人間関係が良好であること`}</strong>{`です｡もちろん馴れ合いの中での業務というわけではなく､各々が`}<strong parentName="p">{`しっかりと仕事に対して責任を持ちつつ､チーム内で生じた違和感は遠慮なく指摘し合い受けとめる`}</strong>{`という､良い意味で心理的安全性のある環境でプロダクト開発に集中して取り組めることです。`}</p>
    <p>{`このような環境で、課題に対して長期的な視点で本質を捉え、目的を設定し､ボトムアップで解決策の立案から改善までを主導すること、さらには仕組み化まで行うことが好きな方､またはそれらをやってみたい方が合っているのではないか､と思います。`}</p>
    <p>{`自分もそのような人間になりたいと思いながら、日々仕事に取り組んでいます。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`ジョブメドレー開発チームに所属している堀内さんに、2 年目の成長と活躍というテーマで話を聞いてきましたが、いかがだったでしょうか？
こんなチームでプロダクトを作っていきたいと思う方は、ぜひお気軽にお話をしましょう!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/db0a02d0cc9c4e10467b5739a13744e0/eea4a/note202302_003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAAB0ESsSgTX/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQMCITIEM//aAAgBAQABBQIVJ84BYsM30YX5/wD/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECIRARcTH/2gAIAQEABj8CvweqfBMjiPD/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhMaEQ/9oACAEBAAE/IaLhW6JWSZAdLMa8a9nGP//aAAwDAQACAAMAAAAQnw//xAAWEQEBAQAAAAAAAAAAAAAAAAARACH/2gAIAQMBAT8QHYv/xAAWEQEBAQAAAAAAAAAAAAAAAAABAEH/2gAIAQIBAT8QHJn/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFh8f/aAAgBAQABPxCoBJAQ09MehQC0uL3YkUqcP2A7BrFkIYfKf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "インタビュアーとインタビュイー",
            "title": "インタビュアーとインタビュイー",
            "src": "/static/db0a02d0cc9c4e10467b5739a13744e0/e5166/note202302_003.jpg",
            "srcSet": ["/static/db0a02d0cc9c4e10467b5739a13744e0/f93b5/note202302_003.jpg 300w", "/static/db0a02d0cc9c4e10467b5739a13744e0/b4294/note202302_003.jpg 600w", "/static/db0a02d0cc9c4e10467b5739a13744e0/e5166/note202302_003.jpg 1200w", "/static/db0a02d0cc9c4e10467b5739a13744e0/eea4a/note202302_003.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      